<template>
  <DocumentList />
</template>

<script>
import DocumentList from "../../components/dashboard/DocumentList.vue";
export default {
  name: "Products",
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Mis Documentos",
      };
    },
  },
  components: {
    DocumentList,
  },
};
</script>

<style scoped>
</style>
