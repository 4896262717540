<template>
  <div>
    <v-row
      v-if="!this.getMembership"
      class="justify-space-between align-center"
    >
      <v-col cols="12" class="">
        <v-card color="py-4">
          <v-card-text>
            <div class="text-center">
              <h2 class="black--text text-h6 text-md-h5 font-weight-bold">
                No hay documentos, adquiera uno de nuestros servicios
              </h2>
              <img
                width="100%"
                class="mt-6"
                alt="undraw_my_files_swob.svg"
                src="../../assets/ilustracions/undraw_my_files_swob.svg"
                height="200"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <template v-else class="">
      <v-row class="justify-space-between align-center">
        <v-col cols="12" md="auto">
          <h2>
            Documentos de {{ this.getMembership.order.details.product.name }}
          </h2>
        </v-col>
      </v-row>
      <v-row class="mx-2 mx-sm-16">
        <v-col cols="12" md="4">
          <v-text-field v-model="search" label="Buscar"></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        style="position: relative"
        no-data-text="No hay documentos asignados a este servicio"
        :headers="headersTable"
        :items="documents"
        :search="search"
        sort-by="full_name"
        class="elevation-1"
        :loading="loadTable"
        loading-text="Por favor espere..."
      >
        <template v-slot:item.extension_name="{ item }">
          <div class="fi fi-size-sm my-2" :class="`fi-${item.extension_name}`">
            <div class="fi-content">{{ item.extension_name }}</div>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            color="grey"
            small
            tag="a"
            ref="download"
            :id="`download-${item.id}`"
          >
            <v-icon small> mdi-file-download-outline </v-icon>
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Refrescar </v-btn>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  async mounted() {
    this.loadTable = true;
    if (this.getMembership) {
      await this.initialize();
      this.loadTable = false;
    }
  },

  data() {
    return {
      isLoading: false,
      documents: [],
      search: "",
      currentFile: null,

      headersTable: [
        { text: "id", value: "id" },
        { text: "Nombre", value: "original_name" },
        { text: "Documento", value: "extension_name" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      loadTable: false,
    };
  },
  computed: {
    ...mapGetters("document", ["getErrors"]),
    ...mapGetters("membership", ["getMembership"]),
  },

  methods: {
    ...mapActions("document", [
      "getDocumentsByProduct",
      "downloadFile",
      "setErrors",
    ]),

    async initialize() {
      this.documents = await this.getDocumentsByProduct(
        this.getMembership.order.details.product.id
      );
      // console.log(this.documents);
      this.documents.forEach(async (element) => {
        await this.makeHrf(element);
      });

      this.setErrors("clear");
    },
    async makeHrf(item) {
      // console.log(item.id);

      const resp = await this.downloadFile(item.id);

      const dataUrl = URL.createObjectURL(resp.data);
      // console.log(dataUrl);

      const ss = document.getElementById(`download-${item.id}`);
      // console.log(ss);

      ss.setAttribute("href", dataUrl);
      ss.setAttribute("download", `${item.original_name}`);

      // console.log("descragando");
    },
  },
};
</script>

<style>
</style>
